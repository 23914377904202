@import 'Shared.module.scss';

.lineupHomePlayer {
    justify-content: left;
    display: flex;
}

.lineupAwayPlayer {
    justify-content: right;
    display: flex;
}

.lineupBenchPlayer {
    color: $disabledColor;
}