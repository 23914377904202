.customUploadFileWrapper {
    background: white;
    height: 50px, ;
    width: 150px;
    border: solid 1px #7b7b7b;
    border-radius: 8px;
    margin-bottom: 1px;
}

.customUploadFileImageWrapper {
    display: flex;
    align-items: center;
    padding-left: 8px;
}

.customUploadFileImage {
    width: 30px;
    max-height: 30px;
}