@use "Shared.module.scss" as *;

.applicationHeader {
    text-align: center;
    color: '#fff';
    height: 64px;
    padding-inline: 200px !important;
    line-height: 64px;
    background-color: $primaryColor !important;
    padding-left: 26px;
    padding-right: 26px;
    z-index: 1000;

    .logo {
        vertical-align: middle;
        height: 18px;
        margin-left: 5px;
    }

    @media only screen and (max-width: 768px) {
        padding-inline: 10px !important;
    }
}

.layout {
    margin-left: 8px;
    margin-right: 8px;
}