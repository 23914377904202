@import 'Shared.module.scss';

.fixtureRow {
    background-color: white;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 2px;
    margin-left: 2px;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 16px;
    }

    .centerSelf {
        align-self: center;
    }
}

.lastColumn {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-right: 20px;
}

.icon {
    margin-right: 5px;
}

.fixtureHeader {
    background-color: $primaryColor;
    width: 100%;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;

    .content {
        color: white;
        justify-content: center;
        display: flex;
    }
}

.textCenter {
    text-align: center !important;
}