$primaryColor: #141414;
$disabledColor: #7b7b7b;

.linkWithoutStyle {
    color: inherit !important;
    text-decoration: inherit !important;
}

.marginTop10 {
    margin-top: 10px;
}

.marginRight10 {
    margin-right: 10px;
}

.marginRight5 {
    margin-right: 5px;
}

.marginLeft5 {
    margin-left: 5px;
}

.fontSize14 {
    font-size: 14px;
}

.fontSize18 {
    font-size: 18px;
}

.fontSize32 {
    font-size: 32px;
}

.fontBold {
    font-weight: 600;
}

.appModal {
    height: 450px;
    overflow: auto;
}

:export {
    primaryColor: $primaryColor;
    disabledColor: $disabledColor;
}