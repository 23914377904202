.mediaListItem {
    text-align: center;
    transition: transform 0.2s ease;
    padding: 5px;
    display: flex;
    position: relative;

    &:hover {
        cursor: pointer;
        transform: scale(1.05);
    }
}

.checkButton {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 5px;
}

.buttonsContainer {
    display: flex;
    gap: 4px;
    margin-bottom: 8px;
}